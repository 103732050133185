import React, {Fragment, useState, useEffect} from "react";
import {Link, useNavigate} from 'react-router-dom';
import moment from 'moment';
import {useAuth} from "../../hooks/useAuth";
import AdminProtected from "../../components/AdminProtected";
import UserService from "../../services/UserService";
import ApplicationService from "../../services/ApplicationService";
import {useTranslation} from "react-i18next";
import StatusBadge from "../../parts/StatusBadge";

const Dashboard = () => {
    const t = useTranslation().t;
    const { user } = useAuth();
    const navigate = useNavigate();
    const [state, setState] = useState({
        user: null,
        users: [],
        userApplications: null,
        firstname: '',
        lastname: '',
        street: '',
        zip: '',
        city: '',
        profileImageUrl: null,
        applications: [],
        lastLogin: []
    });

    let openApplicationsCount = 0;
    let approvedApplicationsCount = 0;
    let deleteRequestsCount = 0;

    useEffect(() => {
        fetch()
    }, [user.id]);// eslint-disable-line react-hooks/exhaustive-deps

    const fetch = async () =>{
        try {
            let applicationResponseData = [];
            let allUsers = [];
            const userResponse = await UserService.getUser(user.id);
            if(user.role === "Administrator"){
                const applicationResponse = await ApplicationService.getApplications();
                applicationResponseData = applicationResponse.data;
                const usersResponse = await UserService.getUsers();
                allUsers = usersResponse.data;
            }
            if(user.role === "Customer" && userResponse.data.existingApplications.length === 0){
                navigate('/member-form');
            }
            setState({ ...state,
                applications: applicationResponseData,
                users: allUsers,
                user: userResponse.data.user,
                userApplications: userResponse.data.existingApplications,
                firstname: userResponse.data.user.firstName,
                lastname: userResponse.data.user.lastName,
                street: userResponse.data.user.street,
                zip: userResponse.data.user.zip,
                city: userResponse.data.user.city,
                lastLogin: userResponse.data.user.lastLogin,
                profileImageUrl: userResponse.data.profileImageUrl,
            });
        } catch (err) {
            console.error(err)
        }
    }

    if(state.applications.length > 0) {
        openApplicationsCount = state.applications.filter(
            application => application.status === 'open'
        ).length;

        approvedApplicationsCount = state.applications.filter(
            application => application.status === 'approved'
        ).length;
    }

    if(state.users.length > 0) {
        deleteRequestsCount = state.users.filter(
            user => user.deleteRequest === true
        ).length;
    }



    let lastLoginMessage = state.lastLogin && state.lastLogin.length > 1
        ? moment(state.lastLogin[0]).format('DD.MM.YYYY - hh:mm')
        : t('txt_first_login');

    const submitRevertDeleteRequest = async () => {
        try {
            await UserService.revertDeleteRequest(user.id, state.user);
            fetch();
        } catch (err) {
            console.error(err);
        }
    }


    return (
        <Fragment>
            <div className='row'>
                <div className='col-12'>
                    <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
                        <h4 className='mb-sm-0'>{t('txt_hello')} {state.firstname ? state.firstname : (state.user ? state.user.email : '')}</h4>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-xxl-4'>
                    {state.user && state.user.deleteRequest && (
                        <div className='card profile-project-card shadow-none alert alert-danger alert-border-left'>
                            <div className='card-body p-2'>
                                <div className='d-flex'>
                                    <div className="flex-grow-1 overflow-hidden">
                                        <strong className="">
                                            {t('txt_deletedFlag_true')}
                                        </strong>
                                        <span onClick={submitRevertDeleteRequest} style={{cursor: "pointer"}}> - Rückgängig machen</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='card profile-project-card shadow-none profile-project-info'>
                        <div className='card-body p-4'>
                            <div className='d-flex'>
                                <div className="flex-grow-1 text-muted overflow-hidden">
                                    <h5 className="fs-14 text-truncate">
                                        <span className="text-body">
                                            {t('txt_last_login')}
                                        </span>
                                    </h5>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                    <div className="badge bg-secondary-subtle text-info fs-10">{lastLoginMessage}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {state.user && state.user.role === "Member" && (
                        <div className="card card-body">
                            <h4 className="card-title">Du bist Mitglied</h4>
                            <p className="card-text text-muted">Hier geht es zu deinem Mitgliedsausweis.</p>
                            <Link to={`/member-card/${user.id}`} className="btn btn-primary">Mitgliedsausweis</Link>
                        </div>
                    )}
                    <AdminProtected>
                        <div className='card profile-project-card shadow-none profile-project-info'>
                            <div className='card-body p-4'>
                                <div className='d-flex'>
                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                        <h5 className="fs-14 text-truncate">
                                        <Link to="/application-list" className="text-body">
                                                {t('txt_requests')}
                                            </Link>
                                        </h5>
                                    </div>
                                    <div className="flex-shrink-0 ms-2">
                                        <div className="badge bg-secondary-subtle text-info fs-10">{t('txt_dashboard_status_open')}</div>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className="flex-grow-1">
                                        <div className="d-flex align-items-center gap-2">
                                            <div>
                                                <h5 className="fs-12 text-muted mb-0">{t('txt_current_requests')} :</h5>
                                            </div>
                                            <div className="avatar-group">
                                                <div className="avatar-group-item">
                                                    <div className="avatar-xs">
                                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                                            {openApplicationsCount}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AdminProtected>
                    <AdminProtected>
                        <div className='card profile-project-card shadow-none profile-project-success'>
                            <div className='card-body p-4'>
                                <div className='d-flex'>
                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                        <h5 className="fs-14 text-truncate">
                                            <Link to="/member-list" className="text-body">
                                                {t('txt_members')}
                                            </Link>
                                        </h5>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className="flex-grow-1">
                                        <div className="d-flex align-items-center gap-2">
                                            <div>
                                                <h5 className="fs-12 text-muted mb-0">{t('txt_current_members')}</h5>
                                            </div>
                                            <div className="avatar-group">
                                                <div className="avatar-group-item">
                                                    <div className="avatar-xs">
                                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                                            {approvedApplicationsCount}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AdminProtected>
                    <AdminProtected>
                        <div className='card profile-project-card shadow-none profile-project-danger'>
                            <div className='card-body p-4'>
                                <div className='d-flex'>
                                    <div className="flex-grow-1 text-muted overflow-hidden">
                                        <h5 className="fs-14 text-truncate">
                                            <Link to="/member-list" className="text-body">
                                                {t('txt_members_deleteRequest')}
                                            </Link>
                                        </h5>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className="flex-grow-1">
                                        <div className="d-flex align-items-center gap-2">
                                            <div>
                                                <h5 className="fs-12 text-muted mb-0">{t('txt_current_deleteRequests')}</h5>
                                            </div>
                                            <div className="avatar-group">
                                                <div className="avatar-group-item">
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title rounded-circle bg-light text-primary">
                                                            {deleteRequestsCount}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            {state.users.filter(user => user.deleteRequest).map((user, index) => (
                                                <div className='mb-1' key={index}>
                                                    <span className="badge bg-danger-subtle text-danger fs-12">{user.email}</span>
                                                    <br/>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AdminProtected>
                </div>
                {
                    state.userApplications && state.userApplications.map((application, index) => {
                        return (
                            <div className={'col-xxl-8'} key={index}>
                                <div className='card profile-project-card shadow-none profile-project-primary'>
                                    <div className="card-header border-0 align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">{t('lbl_request_for')} {application.company ? application.company.companyName : `${application.person.firstName} ${application.person.lastName}`}</h4>
                                    </div>
                                    <div className='card-body p-4'>
                                        <div className={'row mb-2'}>
                                            <div className="fw-medium col-md-6">{t('lbl_current_status')}</div>
                                            <div className={'col-md-6'}>
                                                <StatusBadge status={application.status}/>
                                            </div>
                                        </div>
                                        <div className={'row'}>
                                            <div className="fw-medium col-md-6">{t('lbl_created_date')}</div>
                                            <div className={'col-md-6'}>
                                                {moment(application.createdDate).format('YYYY-MM-DD')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Fragment>
    );
};

export default Dashboard;
